<template>
  <div
    class="homepage-about-brand"
  >
    <div class="center-layout">
      <div class="image-content">
        <MImage
          class="banner-image"
          :src="getImageUrl"
          :alt="getAltText"
          :backdrop="false"
          :picture-breakpoint="601"
        />
      </div>
      <div class="text-content">
        <h3
          v-if="title"
          class="title"
        >
          {{ title }}
        </h3>
        <div
          class="paragraph-1 bodytext"
          v-html="renderRichText(bodytext)"
        />
        <CTA :text="cta[0].text"
             :link="getCtaLink(cta[0])"
             :link-type="getLinkType(cta[0])"
             :target="cta[0].target"
             :layout-option="cta[0].layout_option"
             @click.native="trackCtaClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RichText from 'src/modules/storyblok/mixins/RichText';
import config from 'config'
import windowSize from 'common/mixins/windowSize';
import ImageResize from '../mixins/ImageResize';
import CTA from 'src/modules/storyblok/components/CTA';
import MImage from 'src/common/components/molecules/m-image.vue';

export default {
  name: 'HomepageAboutBrand',
  components: {
    CTA,
    MImage
  },
  mixins: [windowSize, RichText, ImageResize],
  props: {
    title: {
      type: String,
      default: ''
    },
    bodytext: {
      type: Object,
      default: () => {}
    },
    image: {
      type: Object,
      default: () => {}
    },
    cta: {
      type: Array,
      default: () => []
    },
    blockId: {
      type: String,
      default: ''
    }
  },
  computed: {
    getImageUrl () {
      const imageWidth = this.isMobileDevice ? '350' : '568';
      return `${config?.server?.cfImageResizeBaseUrl}/width=${imageWidth},format=webp,quality=80/${this.image?.filename}`;
    },
    getAltText () {
      return this.image?.alt || this.getFilenameFromUrl(this.image)
    }
  },
  methods: {
    getCtaLink (cta) {
      const linkObj = cta?.link || {};
      return linkObj?.url || linkObj?.cached_url;
    },
    getLinkType (cta) {
      return cta?.link?.linktype || '';
    },
    trackCtaClick () {
      this.$gtm?.trackEvent({
        event: `homepage_click`,
        url: this.getCtaLink(),
        homepage_id: 'about_brand_banner',
        name: 'about_brand',
        position: 0
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.homepage-about-brand {
  text-align: center;
  color: var(--neutral-900);
  height: 580px;
  position: relative;

  @include for-mobile {
    padding: 0 20px;
    height: 765px;
  }

  .center-layout {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .text-content {
      width: 50%;
      position: relative;
      text-align: left;
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 0 80px;
      ::v-deep .cms-divider-cta {
        justify-content: left;
        padding: 0;
      }
    }

    .image-content {
      width: 50%;
      position: relative;
      overflow: hidden;
      .banner-image {
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    @include for-mobile {
      padding: 0;
      align-items: center;
      flex-direction: column;
      .text-content {
        width: 95%;
        padding: 40px 0;
      }

      .image-content {
        width: 100%;
        .banner-image {
          position: relative;
          min-height: 315px;
          width: 100%;
        }
      }
    }
  }
  .title {
    margin: 0;
    max-width: 80%;
    @include for-mobile {
      max-width: 100%;
    }
  }
  .bodytext {
    margin-bottom: 40px;
    margin-top: var(--spacing-xl);
  }
}
</style>
