import config from 'config';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getCurrentStoreView'])
  },
  methods: {
    resolveGlobalLinks (richText) {
      const storeCode = this.getCurrentStoreView?.storeCode;
      const globalFolder = config?.storyblok?.globalFolder
      const resolvedJson = JSON.stringify(richText).replace(`/${globalFolder}/`, `/${storeCode}/${globalFolder}/`)
      return JSON.parse(resolvedJson)
    },
    renderRichText (text) {
      return text?.content ? this.$storyblokClient.richTextResolver.render(this.resolveGlobalLinks(text)) : '';
    }
  }
}
